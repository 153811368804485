<template>
	<div class="container">
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item>审批中心</el-breadcrumb-item>
			<el-breadcrumb-item>我的已办</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="table">
			<el-row style="margin: 5px auto; float: right;">
				<el-button type="info" icon="el-icon-refresh" size="small" circle @click="listSelfDone"></el-button>
			</el-row>
			<el-table :data="tableData" border stripe @row-dblclick="handleView">
				<el-table-column type="index" label="#" />
				<el-table-column prop="templateName" label="申请单" />
				<el-table-column prop="unique" label="业务单号" />
				<el-table-column label="申请人">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user">
							{{ scope.row.applier?.nickname }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="taskName" label="执行节点"></el-table-column>
				<el-table-column label="流程状态">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user" :type="flowableType(scope.row.status)">
							{{ flowableStatus(scope.row.status) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="applyTime" label="申请时间" width="160" />
				<el-table-column prop="auditTime" label="审批时间" width="160" />
				<el-table-column label="审批结果">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user" :type="auditType(scope.row.auditStatus)">
							{{ auditStatus(scope.row.auditStatus) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="primary"
							@click="handleView(1, scope.row)"
						>
							审批详情
						</el-button>
						<el-button
							size="mini"
							type="info"
							@click="handleView(2, scope.row)"
						>
							流程图
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				layout="prev, pager, next, total"
				:current-page.sync="pageNo"
				@current-change="listSelfDone"
				:total="total"
			/>
		</div>
		<el-drawer
			:title="mode === 1 ? '审批详情' : '流程图'"
			:visible.sync="show"
			:modal-append-to-body="false"
			:size="mode === 1 ? '500px' : '100%'"
			direction="rtl"
			:modal="false"
			destroy-on-close
		>
			<div v-if="mode === 1">
				<process-timeline-viewer :processInstanceId="processInstanceId" />
			</div>
			<div v-if="mode === 2">
				<process-diagram-viewer />
			</div>
		</el-drawer>
	</div>
</template>
<script>
import ProcessDiagramViewer from '../../common/ProcessDiagramViewer'
import ProcessTimelineViewer from '../../common/ProcessTimelineViewer'
import mixins from '../mixins/mixins'

export default {
	components: {
		ProcessDiagramViewer, ProcessTimelineViewer
	},
	mixins: [mixins],
	data() {
		return {
			show: false,
			mode: 1,
			processInstanceId: '',
			tableData: [],
			total: 0,
			pageNo: 1,
			pageSize: 20
		}
	},
	mounted() {
		this.listSelfDone()
	},
	methods: {
		async listSelfDone() {
			const operator = sessionStorage.getItem('operator')
			const { total, list } = await this.$api.flowable.selfDone({
				operator: operator,
				pageNo: this.pageNo,
				pageSize: this.pageSize
			})
			this.total = total
			this.tableData = list
		},
		async handleView(mode, row) {
			this.mode = mode
			this.processInstanceId = row.processInstanceId
			if (mode === 1) {
				this.show = true
			}
			if (mode === 2) {
				const {
					template, 
					finishedNodeIds, 
					runningNodeIds, 
					waitingNodeIds 
				} = await this.$api.flowable.instanceInfo({
					processInstanceId: row.processInstanceId,
					taskId: row.taskId
				})
				template.process = JSON.parse(template.process)
				this.$store.state.flowable.template = template
				this.$store.state.flowable.finishedNodeIds = finishedNodeIds
				this.$store.state.flowable.runningNodeIds = runningNodeIds
				this.$store.state.flowable.waitingNodeIds = waitingNodeIds
				this.show = true
			}
		},
	}
}
</script>
<style lang="less" scoped>
.container {
	.breadcrumb {
		margin-bottom: 10px;
	}
	.table {
		background-color: #fff;
		padding: 10px;
		border-radius: 5px;
	}
	.user {
		margin-right: 5px;
	}
}
</style>
