<template>
	<div class="block">
		<el-timeline>
			<el-timeline-item
				v-for="(data, index) in datas"
				:key="index"
				:icon="data.icon"
				:type="data.type"
				:timestamp="data.timestamp"
				size="large"
				placement="top"
			>
				<div v-for="(user, idx) in data.users" :key="idx">
					<div class="user-info" v-if="data.userType != 4">
						<span class="nickname">{{ user.nickname }}</span>
						<div class="audit-info">
							<el-tag :type="user.type" v-if="data.userType != 1" size="mini">{{ user.status }}</el-tag>
							<span v-else></span>
							<span>{{ user.datetime }}</span>
						</div>
					</div>
					<div class="comments" v-if="data.userType != 1 && user.status != 1">
						<ellipsis
							hover-tip
							:content="'评论：' + (user.comments || '暂无评论')"
						/>
					</div>
				</div>
			</el-timeline-item>
		</el-timeline>
	</div>
</template>

<script>
import Constant from './Constant.js'
export default {
	props: {
		processInstanceId: {
			type: String,
			default: false
		},
		unique: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			datas: []
		}
	},
	mounted() {
		this.listAssignedUsers()
	},
	methods: {
		async listAssignedUsers() {
			let {status, nodes} = await this.$api.flowable.listAssignedUsers({
				processInstanceId: this.processInstanceId
			})
			this.datas = nodes?.map(item => {
				let nodeStatus = Constant.NODE_STATUS?.find(_ => _.value == item.status)
				return {
					timestamp: item.title,
					type: nodeStatus?.type,
					icon: nodeStatus?.icon,
					userType: item.userType,
					status: nodeStatus?.label,
					users: item.users?.map(user => {
						let status = user.status
						let type = 'success'
						if (item.userType == 3) {
							if (item.status == 0) {
								status = '待抄送'
							} else {
								status = '已抄送'
							}
						} else {
							let userStatus = Constant.AUDIT_STATUS?.find(_ => _.value == user.status)
							status = userStatus?.label
							type = userStatus?.type
						}
						return {
							id: user.id,
							nickname: user.nickname || '',
							avatar: user.avatar || '',
							status: status,
							type: type,
							datetime: user.datetime,
							comments: user.comments
						}
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.block {
	padding: 15px 50px;
	.user-info {
		padding: 4px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.nickname {
			font-size: 16px;
			font-weight: 600;
		}
		.audit-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 60%;
		}
	}
	.comments {
		user-select: none;
		margin: 5px auto;
		padding: 4px 8px;
		border-radius: 4px;
		color: #909399;
		background-color: #f8f8fa;
		font-size: 13px;
	}
}
.el-timeline-item__icon {
	font-size: 20px;
}
.el-timeline-item__node--large {
	left: -5px;
	width: 21px;
	height: 21px;
}
</style>
